import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ChangelogMd from "../data/log.md";
import DocLayout from "../components/DocLayout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  location
}) => {
  return <DocLayout title="Changelog" location={location} noElevation path="/changelog">
      <ChangelogMd />
    </DocLayout>;
};
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      